<template>
  <div>
    <Section1 :keyWord="'Préstamos con ASNEF sin intermediarios'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-left pb-4"><strong>Préstamos con ASNEF sin intermediarios: la solución financiera que estabas buscando</strong></h2>

      <div class="mb-5 d-flex align-items-center justify-content-center">
        <div class="video-container">
          <iframe
                  src="https://www.youtube.com/embed/zhRlf8eMLes?si=k2IMqHxz4RZBffpY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen>
          </iframe>
        </div>
      </div>

      <p>En el mundo de las finanzas, la presencia en el registro de morosos ASNEF puede ser un obstáculo considerable cuando queremos solicitar un préstamo. Sin embargo, hoy en día existen opciones que permiten acceder a préstamos incluso cuando se figura en este listado, y lo mejor de todo, sin intermediarios. En este artículo, exploraremos esta alternativa financiera que puede ser la respuesta a tus necesidades económicas.</p>

      <h3 class="naranja"><strong>¿Qué significa estar en ASNEF y por qué puede ser un obstáculo?</strong></h3>
      <p>Antes de sumergirnos en los préstamos sin intermediarios, es importante comprender qué implica estar en el registro de morosos ASNEF. Este listado, gestionado por la Asociación Nacional de Entidades de Financiación, recopila información sobre deudas impagadas. Figurar en ASNEF puede dificultar la obtención de créditos y préstamos, ya que las entidades financieras tradicionales tienden a verlo como un riesgo.</p>
      <p>La buena noticia es que existen prestamistas especializados que ofrecen préstamos incluso a aquellos que se encuentran en ASNEF, y lo mejor de todo es que puedes acceder a ellos sin la intervención de intermediarios. Al eliminar intermediarios, se agiliza el proceso y se reducen los costos, brindándote una opción más accesible y rápida.</p>

      <h3 class="naranja"><strong>Ventajas de los préstamos sin intermediarios con ASNEF</strong></h3>
      <p>Rapidez en la Aprobación: olvídate de largos procesos y trámites burocráticos. <b>Los préstamos sin intermediarios con ASNEF</b> suelen tener procesos de aprobación más rápidos, permitiéndote obtener el dinero que necesitas en tiempo récord.</p>
      <p>Flexibilidad en las condiciones: a diferencia de las entidades financieras tradicionales, los prestamistas que ofrecen <b>préstamos con ASNEF sin intermediarios</b> suelen ser más flexibles en cuanto a las condiciones de pago. Esto te brinda la oportunidad de adaptar el préstamo a tu situación económica.</p>

      <h3 class="naranja"><strong>Consejos prácticos para elegir el mejor préstamo sin intermediarios con ASNEF</strong></h3>
      <ol>
        <li><b>Investiga varias opciones:</b> no te conformes con la primera opción que encuentres. Investiga diferentes prestamistas, compara tasas de interés y condiciones para asegurarte de elegir la mejor oferta.</li>
        <li><b>Lee las condiciones del préstamo:</b> antes de comprometerte, lee detenidamente las condiciones del préstamo. Asegúrate de entender los plazos de pago, los posibles cargos adicionales y cualquier otra información relevante.</li>
      </ol>
      <p>En definitiva, los <b>préstamos con ASNEF sin intermediarios</b> se presentan como una solución financiera efectiva para aquellos que se encuentran en una situación complicada. La flexibilidad, rapidez y accesibilidad de estas opciones hacen que sean una alternativa a considerar. Al eliminar intermediarios, se simplifica el proceso, brindándote una experiencia más directa y eficiente.</p>
      <p>Desde Ibancar, entendemos la importancia de ofrecer soluciones financieras adaptadas a las necesidades de cada persona. Es por ello que en cada uno de nuestros servicios buscamos proporcionar soluciones rápidas y accesibles para que cada persona pueda superar cualquier obstáculo financiero que se presente en su camino. ¡Descubre una nueva forma de obtener préstamos, sin complicaciones y con la confianza que te ofrece Ibancar!</p>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
          <a href="../prestamos-asnef" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Préstamo con ASNEF</h5>
                <span class="caret-sign">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                  </svg>
                </span>
              <p class="card-text pl-1 pt-2">Obtén un préstamo con ASNEF. Te ayudamos a conseguir el dinero que necesitas, incluso si estás en ASNEF.</p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'

import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamosAsnefSinIntermediarios',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamos con ASNEF sin intermediarios | Ibancar',
      meta:[
        {name:'robots', content:'index'},
        {name:'description', content:'Obtén dinero con ASNEF sin intermediarios. Online y fácil'},
        {name:'keywords', content:'préstamos con asnef sin intermediarios'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>